import React from "react";

import { FaFacebook, FaInstagram, FaTwitter } from "react-icons/fa";

const Footer = () => {
  return (
    <div className="w-screen text-gray-300 py-6 px-4 bg-zinc-50 drop-shadow-[0_5px_35px_rgba(0,0,0,0.25)]">
      <div className="flex flex-col max-w-[1240px] px-2 py-4 m-auto items-center justify-between sm:flex-row text-center text-gray-500">
        <p>2022 Turbo Cinco. Todos los derechos reservados</p>
        <div className="flex justify-between items-center w-[100px] pt-4 sm:pt-0 text-2xl">
          <a target="_blank" href="https://www.facebook.com/turbocinco/">
            <FaFacebook />
          </a>
          <a target="_blank" href="https://www.instagram.com/turbo_cinco/">
            <FaInstagram />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
