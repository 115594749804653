import React, { useState } from "react";
import axios from "axios";

import bgContact from "../assets/bg-contact.jpg";

const Contact = () => {
  const [formStatus, setFormStatus] = useState(false);
  const [query, setQuery] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = () => (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQuery((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    Object.entries(query).forEach(([key, value]) => {
      formData.append(key, value);
    });

    axios
      .post("https://formspree.io/f/xbjwqqyg", formData, {
        headers: { Accept: "application/json" },
      })
      .then(function (response) {
        setFormStatus(true);
        setQuery({
          name: "",
          email: "",
          message: "",
        });
        console.log(response);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  return (
    <div name="contact" className="w-full flex flex-col justify-between">
      {/* Encabezado */}
      <div className="w-full h-[400px] bg-[#0c0c0ca9] absolute">
        <img
          className="w-full h-full object-cover mix-blend-overlay"
          src={bgContact}
          alt="/"
        />
      </div>
      <div className="h-screen max-h-[400px] relative">
        <div className="h-full pt-[80px] flex flex-col justify-center text-center text-white">
          <div className=" text-center mb-5 ">
            <h1 className="text-4xl sm:text-5xl font-bold inline border-b-4 border-[#0cb7f2]">
              Contacto
            </h1>
          </div>
        </div>
      </div>
      {/* Formulario */}
      <form
        onSubmit={handleSubmit}
        className="flex flex-col max-w-[600px] w-full my-20 mx-auto form px-10 "
      >
        <div className="pb-8">
          <p className="py-4">
            Déjanos tu mensaje que a la mayor brevedad nos estaremos comunicando
            contigo.
          </p>
        </div>
        <div className=" form-group flex flex-col max-w-[600px] w-full form text-gray-700">
          <label htmlFor="name"></label>
          <input
            type="text"
            className="form-control bg-zinc-100 p-2"
            id="name"
            placeholder="Tu nombre"
            required="required"
            name="name"
            value={query.name}
            onChange={handleChange()}
          />
          <label htmlFor="email" required="required"></label>
          <input
            type="email"
            className="form-control bg-zinc-100 p-2 my-4"
            id="email"
            required="required"
            placeholder="Tu email"
            name="email"
            value={query.email}
            onChange={handleChange()}
          />
          <label htmlFor="message"></label>
          <textarea
            type="text"
            className="form-control bg-zinc-100 p-2"
            id="message"
            placeholder="Tu mensaje"
            required="required"
            name="message"
            value={query.message}
            rows="10"
            onChange={handleChange()}
          />
        </div>

        <div className="form-group w-full flex flex-col">
          {formStatus ? (
            <div className="text-center pt-3">Tu mensaje ha sido enviado</div>
          ) : (
            ""
          )}
          <button
            type="submit"
            className="mx-auto text-white  border-2 px-4 py-3 my-4 bg-[#004173] hover:border-[#004173] hover:text-[#004173]"
          >
            Enviar mensaje
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
