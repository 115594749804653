import React from "react";
import bgWorkshops from "../assets/bg-workshops.jpg";

import tresPilares from "../assets/taller-3-pilares.jpg";
import clima from "../assets/taller-clima.jpg";
import comunicacion from "../assets/taller-comunicacion.jpg";
import innovacion from "../assets/taller-innovacion.jpg";
import liderazgo from "../assets/taller-liderazgo.jpg";
import metas from "../assets/taller-metas.jpg";
import trabajoEquipo from "../assets/taller-trabajo-equipo.jpg";

const Workshops = () => {
  return (
    <div name="home" className="w-full flex flex-col justify-between">
      {/* Encabezado */}
      {/* Background */}
      <div className="w-full h-[400px] bg-[#0c0c0ca9] absolute">
        <img
          className="w-full h-full object-cover mix-blend-overlay"
          src={bgWorkshops}
          alt="/"
        />
      </div>
      {/* Texto */}
      <div className="h-screen max-h-[400px] relative">
        <div className="h-full pt-[80px] flex flex-col justify-center text-center text-white">
          <div className=" text-center mb-5 ">
            <h1 className="text-4xl sm:text-5xl font-bold inline border-b-4 border-[#0cb7f2]">
              Talleres
            </h1>
          </div>
        </div>
      </div>

      {/* Talleres */}
      <div name="about" className="w-full my-20 ">
        <div className="max-w-[1240px] mx-auto">
          {/* Párrafo de inicio */}
          <div className="text-xl text-center mb-10 px-2">
            <p className="">
              Logra que a través de diferentes metodologías aplicadas, los
              participantes adquieran destrezas, recursos y estrategias
              internas,{" "}
              <strong>
                que les permita realizar procesos de acompañamiento en sus
                tareas como líderes{" "}
              </strong>
              integrales, orientados hacia la acción, lograr las metas y la
              obtención de resultados satisfactorios.
              <br />
              <br />
              Dentro de nuestro portafolio de talleres contamos con los
              siguientes:
            </p>
          </div>
          {/* Grid talleres */}
          <div className="grid  md:grid-cols-2 gap-8 sm:px-2">
            {/* Taller 1 - 3 Pilares */}
            <div className="h-full flex flex-col justify-between border py-10 px-7 rounded-xl shadow-xl ">
              <div className=" text-center mb-5 md:h-[8%] ">
                <h2 className="text-3xl font-bold inline border-b-4 border-[#0cb7f2]">
                  3 Pilares de Autoconocimiento para Jóvenes y Adultos
                </h2>
              </div>
              <div className="flex flex-col justify-between md:h-[92%]  text-justify ">
                <div>
                  <img
                    className="w-[200px] mx-auto rounded-[10%] border-2 border-[#004173] outline-none my-5"
                    src={tresPilares}
                    alt=""
                  />
                  <p className="text-xl py-5 ">
                    <strong>LEGO®SERIOUS PLAY® y OutDoor.</strong> ¡¡Donde
                    aprenderán jugando…!! A:
                  </p>
                  <ul className="list-disc pl-5 text-xl">
                    <li>
                      Despertar la consciencia,{" "}
                      <strong>
                        {" "}
                        descubrir las CREENCIAS que puedan limitar tu desarrollo
                      </strong>
                      , eficacia cotidiana, amarrando unos lastres para lograr
                      frenar tus metas.
                    </li>
                    <li>
                      Descubrir Los VALORES que son inculcados a través del
                      ejemplo y con el ejemplo transformarlos, siendo coherentes
                      con lo que{" "}
                      <strong>PIENSAN, SIENTEN, DICEN y HACEN.</strong>
                    </li>
                    <li>
                      Aprender a reconocer y{" "}
                      <strong> afrontar sus EMOCIONES </strong>como la rabia,
                      alegría, tristeza y miedos ante la realización de sus
                      objetivos y de lo que quieren tener
                    </li>
                  </ul>
                </div>
                <div className="flex justify-center w-full">
                  <a href="/contacto">
                    <button className="max-w-[250px] mx-auto px-5 py-3 font-bold mt-5">
                      Reserva tu taller
                    </button>
                  </a>
                </div>
              </div>
            </div>
            {/* Taller 2- Com asertiva */}
            <div className="h-full flex flex-col justify-between border py-10 px-7 rounded-xl shadow-xl ">
              <div className="text-center mb-5 md:h-[8%]">
                <h2 className="text-3xl font-bold inline border-b-4 border-[#0cb7f2]">
                  Comunicación Asertiva y Efectiva
                </h2>
              </div>
              <div className="flex flex-col justify-between md:h-[92%]  text-justify">
                <div>
                  <img
                    className="w-[200px] mx-auto rounded-[10%] border-2 border-[#004173] outline-none my-5"
                    src={comunicacion}
                    alt=""
                  />
                  <p className="text-xl py-5">
                    Es uno de los procesos más importantes y complejos que el
                    ser humano realiza y requiere. Cualquier tarea dependerá
                    directamente de lo bien que logre comunicarse entre las
                    partes.
                    <br />
                    <br />
                    Al ser la comunicación un proceso de envio y recibo de
                    información ésta debe ser clara, precisa, directa, para que
                    el objetivo y la acción vayan en la misma dirección.{" "}
                    <strong>
                      {" "}
                      Con la Metodología Lego Serious Play Se demuestra una
                      comunicación necesaria y fluida, que expresa conceptos,
                      proyectos e ideas con mayor Facilidad.
                    </strong>
                  </p>
                </div>
                <div className="flex justify-center w-full">
                  <a href="/contacto">
                    <button className="max-w-[250px] px-5 py-3 font-bold mt-5">
                      Reserva tu taller
                    </button>
                  </a>
                </div>
              </div>
            </div>
            {/* Taller 3 - Innovacion y creatividad */}
            <div className="h-full flex flex-col justify-between border py-10 px-7 rounded-xl shadow-xl ">
              <div className="text-center mb-5 md:h-[72px]">
                <h2 className="text-3xl font-bold inline border-b-4 border-[#0cb7f2]">
                  Innovación y Creatividad
                </h2>
              </div>
              <div className="flex flex-col justify-between md:h-[92%]  text-justify">
                <div>
                  <img
                    className="w-[200px] mx-auto rounded-[10%] border-2 border-[#004173] outline-none my-5"
                    src={innovacion}
                    alt=""
                  />
                  <p className="text-xl py-5">
                    La Innovación y creatividad es la elaboración, desarrollo y
                    puesta en marcha de distintos planes operativos por parte de
                    las empresas u organizaciones,{" "}
                    <strong>
                      {" "}
                      con la intención de alcanzar objetivos y metas planteadas.{" "}
                    </strong>{" "}
                    Estos planes pueden ser a corto, mediano o largo plazo.
                    <br />
                    <br />
                    Con Lego Serious Play permite la construcción de una visión
                    compartida sobre el rumbo estratégico de la empresa y de un
                    plan de acción de una manera innovadora,{" "}
                    <strong>
                      {" "}
                      permitiendo a los equipos de trabajo tener una definición
                      de sus objetivos{" "}
                    </strong>
                    , y metas planteadas en tiempo real de una manera 100/100.
                  </p>
                </div>
                <div className="flex justify-center w-full">
                  <a href="/contacto">
                    <button className="max-w-[250px] px-5 py-3 font-bold mt-5">
                      Reserva tu taller
                    </button>
                  </a>
                </div>
              </div>
            </div>

            {/* Taller 4 - Clima laboral */}
            <div className="h-full flex flex-col justify-between border py-10 px-7 rounded-xl shadow-xl ">
              <div className="text-center mb-5 md:h-[72px]">
                <h2 className="text-3xl font-bold inline border-b-4 border-[#0cb7f2]">
                  Clima Laboral
                </h2>
              </div>
              <div className="flex flex-col justify-between md:h-[92%]  text-justify">
                <div>
                  <img
                    className="w-[200px] mx-auto rounded-[10%] border-2 border-[#004173] outline-none my-5"
                    src={clima}
                    alt=""
                  />
                  <p className="text-xl py-5">
                    Es el ambiente generado por las emociones de los miembros de
                    un grupo u organización, el cual está relacionado con la
                    motivación de los empleados y se asimila al de dinámica de
                    grupo al analizar las fuerzas internas que inciden en el
                    ambiente laboral como resistencia al cambio.
                    <br />
                    <br />
                    Con Lego Serious Play{" "}
                    <strong>
                      {" "}
                      el primer mecanismo para mejorar el ambiente laboral es
                      conocer realmente la situación en la empresa y este es
                      logrado a través de la construcción con LEGO®.
                    </strong>{" "}
                    La construcción permite que las conversaciones fluyan sin
                    temor de pisar los sentimientos personales.
                  </p>
                </div>
                <div className="flex justify-center w-full">
                  <a href="/contacto">
                    <button className="max-w-[250px] px-5 py-3 font-bold mt-5">
                      Reserva tu taller
                    </button>
                  </a>
                </div>
              </div>
            </div>

            {/* Taller 5 - alcanzar metas */}
            <div className="h-full flex flex-col justify-between border py-10 px-7 rounded-xl shadow-xl ">
              <div className="text-center mb-5 md:h-[8%]">
                <h2 className="text-3xl font-bold inline border-b-4 border-[#0cb7f2]">
                  ¿Cómo alcanzar tus metas?
                </h2>
              </div>
              <div className="flex flex-col justify-between md:h-[92%]  text-justify">
                <div>
                  <img
                    className="w-[200px] mx-auto rounded-[10%] border-2 border-[#004173] outline-none my-5"
                    src={metas}
                    alt=""
                  />
                  <p className="text-xl py-5">
                    Establecer metas y objetivos, es lo que hace la diferencia
                    en las personas exitosas. Por eso, plantearse propósitos, no
                    solo le dan sentido a la vida, sino que permiten mantenerse
                    motivado y enfocado al éxito.
                    <br />
                    <br />{" "}
                    <strong>
                      Con Lego Serious Play® las experiencias desencadenan
                      cambios significativos de pensamientos y sentimientos
                    </strong>
                    , ya que la creación en tiempo real de los objetivos de vida
                    sean tangibles y visibles.
                  </p>
                </div>
                <div className="flex justify-center w-full">
                  <a href="/contacto">
                    <button className="max-w-[250px] px-5 py-3 font-bold mt-5">
                      Reserva tu taller
                    </button>
                  </a>
                </div>
              </div>
            </div>
            {/* Taller 6 - liderazgo */}
            <div className="h-full flex flex-col justify-between border py-10 px-7 rounded-xl shadow-xl ">
              <div className="text-center mb-5 md:h-[8%]">
                <h2 className="text-3xl font-bold inline border-b-4 border-[#0cb7f2]">
                  Desarrollo de Liderazgo
                </h2>
              </div>
              <div className="flex flex-col justify-between md:h-[92%]  text-justify">
                <div>
                  <img
                    className="w-[200px] mx-auto rounded-[10%] border-2 border-[#004173] outline-none my-5"
                    src={liderazgo}
                    alt=""
                  />
                  <p className="text-xl py-5">
                    Permite explorar el conjunto de habilidades gerenciales,
                    directivas o incluso personales, que un individuo tiene para
                    influir en la forma de ser de otras personas o de un grupo
                    de personas determinado, haciendo que este equipo trabaje
                    con entusiasmo y compromiso, en el logro de los resultados.
                    <br />
                    <br />{" "}
                    <strong>
                      Con Lego Serious Play y Actividades Outdoor el líder puede
                      referenciar los lideres de su equipo
                    </strong>
                    , y los roles de acuerdo a sus capacidades.
                  </p>
                </div>
                <div className="flex justify-center w-full">
                  <a href="/contacto">
                    <button className="max-w-[250px] px-5 py-3 font-bold mt-5">
                      Reserva tu taller
                    </button>
                  </a>
                </div>
              </div>
            </div>
            {/* Taller 7 - trabajo en equipo */}
            <div className="md:col-span-2 max-w-[596px] mx-auto h-full  flex flex-col justify-between border py-10 px-7 rounded-xl shadow-xl ">
              <div className="text-center mb-5 md:h-[72px]">
                <h2 className="text-3xl font-bold inline border-b-4 border-[#0cb7f2]">
                  Trabajo en equipo
                </h2>
              </div>
              <div className="flex flex-col justify-between md:h-[92%]  text-justify">
                <div>
                  <img
                    className="w-[200px] mx-auto rounded-[10%] border-2 border-[#004173] outline-none my-5"
                    src={trabajoEquipo}
                    alt=""
                  />
                  <p className="text-xl py-5">
                    Crea conciencia individual, sobre la importancia de trabajar
                    mancomunadamente entre los miembros de la organización,
                    <strong>
                      {" "}
                      con el propósito de lograr un objetivo común y que la
                      productividad de la compañía sea la esperada.{" "}
                    </strong>
                    <br />
                    <br />
                    Un excelente trabajo en equipo, aporta directamente al buen
                    clima laboral.
                  </p>
                </div>
                <div className="flex justify-center w-full">
                  <a href="/contacto">
                    <button className="max-w-[250px] px-5 py-3 font-bold mt-5">
                      Reserva tu taller
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Workshops;
