import React from "react";

import bgAbout from "../assets/bg-about.jpg";

const About = () => {
  return (
    <div className="w-full flex flex-col justify-between">
      {/* Encabezado */}
      <div className="w-full h-[400px] bg-[#0c0c0ca9] absolute">
        <img
          className="w-full h-full object-cover mix-blend-overlay"
          src={bgAbout}
          alt="/"
        />
      </div>
      <div className="h-screen max-h-[400px] relative">
        <div className="h-full pt-[80px] flex flex-col justify-center text-center text-white">
          <div className=" text-center mb-5 ">
            <h1 className="text-4xl sm:text-5xl font-bold inline border-b-4 border-[#0cb7f2]">
              Julián de la Cruz
            </h1>
          </div>
          <p className="w-[250px] mx-auto text-zinc-400">
            Ingeniero de Sistemas, Coach, Train LEGO® SERIOUS PLAY
          </p>
        </div>
      </div>
      {/* About */}
      <div className="w-full my-20">
        <div className="flex flex-col justify-center items-center w-full h-full">
          <div className="mx-w-[1000px] w-full grid grid-cols-2 gap-8">
            <div className="col-span-2 text-center">
              <p className="text-4xl font-bold inline border-b-4 border-[#0cb7f2]">
                Acerca de
              </p>
            </div>
            <div></div>
          </div>
          <div className="flex flex-col justify-center max-w-[1000px]">
            <p className=" px-4 gap-12 columns-xs text-justify">
              Soy un hombre Colombiano, vivo en Cali, soy padre y esposo,
              Ingeniero de Sistemas de profesión,{" "}
              <strong>Coach Ejecutivo y de Vida </strong> , facilitador de la
              Metodología (LEGO® SERIOUS PLAY®) y Líder en Yoga de la Risa.
              <br />
              <br />
              Convencido de que las personas aprenden jugando, jóven muy pilo,
              que es feliz haciendo lo que hace.
              <br />
              <br />
              <strong> Vivo no del tiempo sino de los momentos </strong>, me
              gusta disfrutar de la naturaleza, los deportes y jugar con mi niño
              interior en mis ratos libres.
              <br />
              <br />
              Me apasiona hablar, escuchar a la gente, reírme,{" "}
              <strong> ser yo mismo y no un acartonado</strong>, una de las
              cosas que me encanta al hacer coaching es ver a las personas
              cuando logran sacar de su closet mental sus pensamientos
              negativos, creencias limitantes y logran transformarlas.
              <br />
              <br />
              Los cambios para mi en la vida, <strong>
                {" "}
                son constantes{" "}
              </strong>{" "}
              y para poder transformar la vida de otras personas, debo hacerla
              con la mía primero.
              <br />
              <br />
              He logrado que en mis{" "}
              <a className="underline font-bold text-[#004173]" href="/talleres">
                {" "}
                talleres
              </a>{" "}
              se diviertan y a la vez sean conscientes de cómo creencias,
              valores y emociones han marcado sus formas de vida y limitado en
              muchos aspectos, que{" "}
              <strong> sea una experiencia reveladora </strong>, he logrado
              fortalecer actitudes, aptitudes y habilidades, que se reinventen,
              logren reconocer en que deben trabajar y seguir sus caminos de una
              manera más armónica y en deportistas de alto rendimiento a como
              enfrentar sus miedos en competencias.
            </p>
            <p className="font-bold text-2xl sm:text-3xl text-center py-8">
              Mi propósito es que reconozcas el poder, aceleramiento y arranque
              que hay en ti e identifiques qué te está limitando a que cumplas
              tus sueños.
            </p>
            <a className="mx-auto" href="/talleres">
              <button className=" w-[250px] p-4 font-bold">
                Conoce mis talleres
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
