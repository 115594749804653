import React, { useState } from "react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

import logo from "../assets/logo.png";

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  const handleClose = () => setNav(!nav);

  return (
    <header className="w-screen h-[80px] z-10 bg-zinc-50 fixed top-0 drop-shadow-lg">
      <div className=" max-w-[1240px] m-auto px-2 flex justify-between items-center w-full h-full">
        <div className="flex items-center">
          <a className="w-full h-full" href="/">
            <img className="w-[120px]" src={logo} alt="" />
          </a>
        </div>
        <div className="hidden md:flex pr-4">
          <ul className="hidden md:flex">
            <li>
              <a href="/">Inicio</a>
            </li>
            <li>
              <a href="/acerca-de">Acerca de</a>
            </li>
            <li>
              <a href="/talleres">Talleres</a>
            </li>
          </ul>
          <div className="flex items-center">
          <a className="ml-4" href="/contacto">
            <button className="px-8 py-3">Contacto</button>
          </a>
          </div>
        </div>
        <div className="md:hidden mr-4" onClick={handleClick}>
          {!nav ? <MenuIcon className="w-5" /> : <XIcon className="w-5" />}
        </div>
      </div>

      {/* Menu Mobile */}
      <ul
        className={
          !nav ? "hidden" : "absolute bg-zinc-50 w-full px-8 text-center "
        }
      >
        <li className="border-b-2 border-zinc-300 w-full hover:bg-zinc-100">
          <a href="/">Inicio</a>
        </li>
        <li className="border-b-2 border-zinc-300 w-full hover:bg-zinc-100">
          <a href="/acerca-de">Acerca de</a>
        </li>
        <li className="border-b-2 border-zinc-300 w-full hover:bg-zinc-100">
          <a href="/talleres">Talleres</a>
        </li>

        <div className="flex flex-col my-4">
          <a href="/contacto">
            <button className="px-8 py-3 m-auto w-[200px]">Contacto</button>
          </a>
        </div>
      </ul>
    </header>
  );
};

export default Navbar;
