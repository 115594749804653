import React from "react";

import ArteFrances from "../assets/logos/lg-arte-frances.png";
import BancoOccidente from "../assets/logos/lg-bancoccidente.png";
import Bristol from "../assets/logos/lg-bristol.png";
import CCC from "../assets/logos/lg-ccc.png";
import Fondeicon from "../assets/logos/lg-fondeicon.png";
import GoodYear from "../assets/logos/lg-goodyear.png";
import Incocredito from "../assets/logos/lg-incocredito.png";
import SURA from "../assets/logos/lg-sura.png";
import UniCauca from "../assets/logos/lg-unicauca.png";
import UPB from "../assets/logos/lg-upb.png";

import Andrea from "../assets/test-andrea.jpg";
import Carlos from "../assets/test-carlos.jpg";
import Nicolle from "../assets/test-nicolle.jpg";
import Roberto from "../assets/test-roberto.jpg";

import bgInicio from "../assets/bg-inicio.jpg";

const Hero = () => {
  return (
    <div className="w-full flex flex-col justify-between">
      {/* Inicio */}
      {/* Img Background */}
      <div className="w-full h-screen max-h-[720px] bg-[#0c0c0c85]  absolute">
        <img
          className="w-full h-full object-cover mix-blend-overlay"
          src={bgInicio}
          alt="/"
        />
      </div>
      {/* Text initial */}
      <div className="h-screen max-h-[720px] text-white grid md:grid-cols-3 2xl:grid-cols-4 mx-auto relative">
        <div className="flex items-end text-white pb-6 md:items-start sm:pl-6  md:pt-[160px] ">
          <div>
            <h1 className="text-[#0cb7f2]">¡Hola!</h1>
            <p className="text-zinc-200 text-3xl font-bold">
              Soy Julián de la Cruz
            </p>
            <h2 className="text-zinc-400 text-xl">
              Coach Empresarial & de Vida
            </h2>
            <a href="/acerca-de">
              <button className="py-2 px-4 mt-2 hover:text-white hover:border-[#0cb7f2]">
                Conóceme
              </button>
            </a>
          </div>
        </div>
      </div>
      {/* Acerca de */}
      <div className="w-full my-20">
        <div className="flex flex-col justify-center items-center w-full h-full">
          <div className="mx-w-[1000px] w-full grid grid-cols-2 gap-8">
            <div className="col-span-2 sm:col-span-1 text-center sm:text-right sm:pb-8 sm:pl-4">
              <p className="text-4xl font-bold inline border-b-4 border-[#0cb7f2]">
                Acerca de
              </p>
            </div>
            <div></div>
          </div>
          <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
            <div className="flex items-center sm:text-right text-4xl font-bold">
              <p>Hola. Soy Juli, conoce un poco acerca de mi</p>
            </div>
            <div>
              <p>
                Soy Colombiano y vivo en España, soy padre y esposo, Ingeniero
                de Sistemas de profesión, Coach Ejecutivo de Vida, Facilitador
                de la Metodología (LEGO® SERIOUS PLAY®). Un convencido de que
                las personas aprenden jugando, jóven muy pilo, que es feliz
                haciendo lo que hace. Vivo no del tiempo sino de los momentos,
                me gusta disfrutar de la naturaleza, los deportes y jugar con mi
                niño interior en mis ratos libres.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Algunos de nuestros clientes */}
      <div className="bg-[#0C0C0C] ">
        <div className="max-w-[1000px] mx-auto px-4 my-20 flex flex-col justify-center w-full h-full">
          <div className="text-gray-300">
            <p className="text-4xl font-bold inline border-b-4 border-[#0cb7f2] ">
              Algunos de nuestros clientes
            </p>
            <p className="py-4">
              Llevamos ya una trayectoria de tres años en el mercado Colombiano,
              gracias a esto hemos trabajado con empresas prestigiosas del país.
            </p>
          </div>

          <div className="w-full grid grid-cols-2 sm:grid-cols-5 gap-4 text-center">
            <div className="shadow-md bg-zinc-200 shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-20 mx-auto "
                src={ArteFrances}
                alt="Arte Francés Logo"
              />
              <p className="my-4">Arte Francés</p>
            </div>
            <div className="shadow-md  bg-zinc-200 shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-20 mx-auto "
                src={BancoOccidente}
                alt="Banco de Occidente Logo"
              />
              <p className="my-4">Banco de Occidente</p>
            </div>
            <div className="shadow-md  bg-zinc-200 shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto " src={Bristol} alt="Bristol icon" />
              <p className="my-4">Bristol</p>
            </div>
            <div className="shadow-md  bg-zinc-200 shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-20 mx-auto "
                src={CCC}
                alt="Cámara de Comercio de Cali Logo"
              />
              <p className="my-4">Cámara de Comercio</p>
            </div>
            <div className="shadow-md  bg-zinc-200 shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-20 mx-auto "
                src={Fondeicon}
                alt="Fondeicon Logo"
              />
              <p className="my-4">Fondeicon</p>
            </div>
            <div className="shadow-md  bg-zinc-200 shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-20 mx-auto "
                src={GoodYear}
                alt="Goodyear logo"
              />
              <p className="my-4">Goodyear</p>
            </div>
            <div className="shadow-md  bg-zinc-200 shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-20 mx-auto "
                src={Incocredito}
                alt="Incocredito logo"
              />
              <p className="my-4">Incocrédito</p>
            </div>
            <div className="shadow-md  bg-zinc-200 shadow-[#040c16] hover:scale-110 duration-500">
              <img className="w-20 mx-auto " src={SURA} alt="Sura logo" />
              <p className="my-4">Sura</p>
            </div>
            <div className="shadow-md  bg-zinc-200 shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-20 mx-auto "
                src={UniCauca}
                alt="Universidad del Cauca logo"
              />
              <p className="my-4">Unicauca</p>
            </div>
            <div className="shadow-md  bg-zinc-200 shadow-[#040c16] hover:scale-110 duration-500">
              <img
                className="w-20 mx-auto "
                src={UPB}
                alt="Universidad Pontificia Bolivariana logo"
              />
              <p className="my-4">Upb</p>
            </div>
          </div>
        </div>
      </div>

      {/* Testimonios */}
      <div className="w-full my-20 ">
        <div className="max-w-[1240px] mx-auto">
          <div className=" text-center mb-10 ">
            <h2 className="text-4xl font-bold inline border-b-4 border-[#0cb7f2]">
              Nuestros clientes opinan
            </h2>
          </div>
          {/* Grid testimonios */}
          <div className="grid md:grid-cols-2 gap-5 px-2 text-center">
            <div className=" flex flex-col justify-between border p-8 rounded-xl shadow-xl ">
              <img
                className="w-[100px] mx-auto rounded-[50%] border-2 border-[#004173] outline-none mb-5"
                src={Roberto}
                alt=""
              />
              <p className="text-xl">
                Para mí ha sido una experiencia reveladora; he conocido más de
                mí de lo q yo creía, estoy logrando; a base de poner un poco más
                de atención, hacer consciente lo inconsciente y de esta manera
                lograr fortalecer actitudes, aptitudes y habilidades; ha sido
                maravilloso.
              </p>
              <p className="text-gray-400 mt-2 text-right">Roberto Ramirez</p>
            </div>
            <div className="flex flex-col justify-between border p-8 rounded-xl shadow-xl ">
              <img
                className="w-[100px] mx-auto rounded-[50%] border-2 border-[#004173] outline-none mb-5"
                src={Nicolle}
                alt=""
              />
              <p className="text-xl ">
                Julian es una gran persona y tiene la habilidad de hacer que
                caigamos en cuenta que debemos trabajar. Ahora solo me queda
                seguir mi propio camino para continuar viviendo el AHORA de una
                manera más armónica. Muchas Gracias Juli por todas las nuevas
                enseñanzas. ¡Eres un excelente guía!
              </p>
              <p className="text-gray-400 mt-2 text-right">Nicolle Echeverri</p>
            </div>
            <div className="flex flex-col justify-between border p-8 rounded-xl shadow-xl ">
              <img
                className="w-[100px] mx-auto rounded-[50%] border-2 border-[#004173] outline-none mb-5"
                src={Andrea}
                alt=""
              />
              <p className="text-xl">
                Soy Andrea y estoy realizando en este momento el taller «3
                pilares para mujeres» me ha parecido un sistema novedoso,
                divertido, descrestante y muy interesante para desaprender
                creencias y volverme consciente de cómo estas han marcado mi
                forma de vida y me han limitado en muchísimos aspectos. Es un
                taller que me ha abierto la mente para aprender y ser consiente
                de todo lo que sucede en mi vida. Me encantó y su metodología es
                increíble.
              </p>
              <p className="text-gray-400 mt-2 text-right">Andrea Zapata</p>
            </div>
            <div className="flex flex-col justify-between border p-8 rounded-xl shadow-xl ">
              <img
                className="w-[100px] mx-auto rounded-[50%] border-2 border-[#004173] outline-none mb-5"
                src={Carlos}
                alt=""
              />
              <p className="text-xl ">
                Hace poco hice el taller de «3 pilares». Cuando inició tenía
                muchas dudas, pensé que iba hacer otro taller más, que
                probablemente no iba a terminar (eso siempre me había pasado)
                pero lo empecé porque me llamo mucho la atención el tema del
                LEGO y como soy docente de preescolar, me acorde que los niños a
                través del Juego es que aprenden. Pero en mi caso lo que hice
                fue desaprender y dejar que todo fluyera para volver a aprender.
                No me retire y logre hacer todo el taller. Me gustó mucho la
                técnica y el hecho de estar más consciente de mí actuar.{" "}
              </p>
              <p className="text-gray-400 mt-2 text-right">Carlos Gomez</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
